import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";

const orderItemsApi = factory.get("orderItems");

const initialState = {
  orderItems: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      skip: 0,
      take: 15,
      page: 0,
      serial_number: "",
      location_id: null,
      id: null,
      from_type: "Store",
      from_id: null,
      to_type: "Store",
      to_id: null,
    },
    filter_dialog: false,
  },
};

export const orderItemsSlice = createSlice({
  name: "orderItems",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setOrderItems: (state, action) => {
      state.orderItems.data = action.payload.data.data;
      state.orderItems.total = action.payload.data.total;
    },
    setFilters: (state, { payload }) => {
      state.orderItems.filters = {
        ...state.orderItems.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.orderItems.filters = {
        ...initialState.orderItems.filters,
        from_type: "Store",
        from_id: null,
        to_type: "Store",
        to_id: null,
      };
    },
    setFilterDialog: (state, action) => {
      state.orderItems.filter_dialog = !state.orderItems.filter_dialog;
    },
  },
});

export const {
  setLoading,
  setOrderItems,
  setFilters,
  setDialog,
  resetForm,
  resetFilters,
  setFilterDialog,
} = orderItemsSlice.actions;
export default orderItemsSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().orderItems.orderItems.filters;
    const _filters = {
      ...filters,
      id: filters.id?.id || null,
      location_id: filters.location_id?.id || null,
      from_id: filters.from_id?.id || null,
      to_id: filters.to_id?.id || null,
    };
    dispatch(setLoading("orderItems"));
    const res = await orderItemsApi.index(_filters);
    dispatch(setOrderItems(res));
    dispatch(setLoading("orderItems"));
  } catch (err) {
    dispatch(setLoading("orderItems"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
export const exportHistory = () => async (dispatch, getState) => {
  try {
    const filters = getState().orderItems.orderItems.filters;
    const _filters = {
      id: filters.id?.id || null,
      location_id: filters.location_id?.id || null,
      export: "excel",
    };
    dispatch(setLoading("orderItems"));
    const res = await orderItemsApi.exportToExcel(_filters);
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.download = `item-order-history_${dayjs().format(
      "YYYY-MM-DD HH:mm"
    )}.xlsx`;
    a.href = url;
    a.click();
    dispatch(setLoading("orderItems"));
  } catch (err) {
    dispatch(setLoading("orderItems"));
    console.log(err);
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
  }
};
export const OrderItems = {
  index,
  exportHistory,
};
